import React from 'react'
import { Helmet } from "react-helmet"
import './styles.scss'
import { StaticQuery, graphql } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import Glitch from 'components/glitch'
import Drop from 'components/drop'
import Shadow from 'components/shadow'
import Typewriter from 'typewriter-effect'
import Copyright from 'components/copyright'
import OurTeam from 'components/ourteam'
import ThemeContext from '../../context'
/*
import WaterWave from 'react-water-wave';
import image from '../../../content/images/bg/1.jpg';
*/

import $ from 'jquery';
import '../../jquery/ripplejs.js';

/*

global.jQuery = require('jQuery');
import '../../jquery/ripplejs.js'; */

class Hero extends React.Component {

  static contextType = ThemeContext
  constructor(props) {
    super(props)
    this.state = {
      mobile: false,
    }
  }

  setDefaults() {
    this.setState({
      mobile: window.innerWidth < 992 ? true : false,
    })
  }

  render() {
    return (

      <section
        id={`${this.props.id}`}
        className="hero"
        style={{ height: this.context.height }}
      >
        <div className="top-text">
          <div className="line-text">
                  <h4>Welcome To...</h4>
                 {/*  <OurTeam />*/}
          </div>
        </div>
        <img className="centered"
          src={this.props.mainImg.childImageSharp.fluid.src}
          alt="person"
        />
        <div className="bottom-text">
        <Typewriter
                options={{
                  strings: [
                    'A Small Team of',
                    'Interdisciplinary Researchers ',
                    'Engineers',
                    'Designers',
                    'Innovators',
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
        </div>
        <Copyright/>
        <a href="javascript:void(0);" id="rocketdown" class="showrocket" ><i></i></a>
      </section>

    )
  }

  componentDidMount() {
   /* try {
      this.setDefaults()
     // window.jQuery = $; window.$ = $;
     if(typeof window !== 'undefined'){
      let ripples = $.fn.ripples; //accessing jquery function
      $('body').ripples({ //call directly on mount
        resolution: 1024,
        dropRadius: 25,
        perturbance: 0.10,
      });
      if(this.state.mobile){
        //$('body').ripples('updateSize')
        $('body').ripples('destroy');
        $('body').ripples({ //call directly on mount
        resolution: 50,
        dropRadius: 2,
        perturbance: 0.02,
      });
      }
    }
    
    // window.externalLibrary.method()
    } catch (e) {
      console.error(e);
    }*/

  $(window).scroll(function() {

    if ($(this).scrollTop()>0)
     {
        $('#rocketdown').fadeOut();
     }
    else
     {
      $('#rocketdown').fadeIn();
     }
 });

  }

  icons() {
    return this.props.icons.edges.map((value, index) => {
      return (
        <img
          src={value.node.childImageSharp.fluid.src}
          className={`animated fadeIn move-${
            Math.floor(Math.random() * 10) % 2 === 0 ? 'up' : 'down'
            } float-image`}
          style={{
            left: `${index * 10}%`,
            bottom: `${Math.random() *
              (+(index % 2 === 0 ? 80 : 20) - +(index % 2 === 0 ? 70 : 10)) +
              +(index % 2 === 0 ? 70 : 10)}%`,
          }}
          alt="shape"
          key={index}
        />
      )
    })
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        icons: allFile(
          filter: {
            extension: { regex: "/(png)/" }
            relativeDirectory: { eq: "icons" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 100) {
                  src
                }
              }
            }
          }
        }
        Img: file(relativePath: { eq: "one.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              src
            }
          }
        }
      }
    `}
    render={({ icons, Img }) => <Hero icons={icons} mainImg={Img} {...props} />}
  />
)
