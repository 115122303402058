import React from 'react'
import './styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faBars, faMinus } from '@fortawesome/free-solid-svg-icons'

var scrollToElement = require('scroll-to-element')

class TeamNav extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            showModal : false,
            form : ''
        }
        this.sections = [
            {
                name: "Team Login",
                name: "Contact Us"
            }
        ]
    }

 /*   navScroll(id, v) {
        
        this.setState({show: false})
        const el = document.getElementById(id)
        scrollToElement(el, {
            offset: 0,
            ease: 'in-out-expo',
            duration: 2000
          }).on('end', () => {
            this.props.change(v)
        });
    }
    */
     render() {
        return(
            <div>
                <div className="opener">
                    <FontAwesomeIcon icon={faBars} className="closeNav" onClick={() => this.setState({show: true})} />
                </div>
                <div className={`navigation ${this.state.show ? "active" : ""}`}>
                    <FontAwesomeIcon icon={faMinus} className="closeNav" onClick={() => this.setState({show: false})} />
                   <div className="logo">
                      {/*}   <img src="img/logo.png" alt="logo"/>  */}                    
                    </div> 
                    <div className="links">
                        <ul>
                             <li>
                             <a href="/office"><button>Team Login</button></a>                             
                            </li>
                            <li>
                            <a href="mailto:reachus@aparnia.com"> <button>Contact Us</button></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    

}
export default TeamNav