import React from 'react'
import './styles.scss'
import AnimationContainer from 'components/animation-container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import Slider from 'react-slick'
class OurTeam extends React.Component {

    render() {
        const { text } = this.props
        return (


            <div id="container">
                <ul class="timeline">
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Vishwanatha Shiva</span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Saraswathi Jayaraman</span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Anima Lakshmi </span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Lakshmi Gnanam</span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Ramana Balasubramaniam </span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Sujay Badrinath</span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Vijay Premanand</span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Swaroop RS</span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Saketharaman NS</span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Aditya Chandrasekar</span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Akshaya Chandrasekar </span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Lakshmanan MS</span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Rohini Sukumar</span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Vijayalakshmi TS</span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Nadikerianda Cariappa</span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Kaveri KT</span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Anamika Natarajan</span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Pramod Narayanan </span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Dnyaneshwar V</span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Surbha GS</span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Arathi Agarwal </span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Sanjay S</span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Priyam VS </span></li>
                    <li><span class="badge badge-primary badge-pill font-weight-normal">Sharada Vishwanathan</span></li>
                </ul>
            </div>

        )
    }
}
export default OurTeam