import React from 'react'
import './styles.scss'

class Logo extends React.Component {

    render() {
        const { text } = this.props
        return (
            <div className="fixedlogo">
                <a href="/"><img src="img/logo_small.png" alt="aparnia"/> </a>
            </div>

        )
    }
}
export default Logo