import React from 'react'
import Particles from 'react-particles-js';
import Progress from 'components/progress'
import { Row, Col } from 'react-bootstrap'
import BaffleText from 'components/baffle-text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import ThemeContext from '../../context'
import './styles.scss'
import ScrollTop from "react-scrolltop-button";
import $ from 'jquery';

class Hero extends React.Component {

    static contextType = ThemeContext

    componentDidMount(){
        let scroll = $.fn.scroll;
        $(window).scroll(function(){
            if($(window).scrollTop()<50){
                $('#rocketmeluncur').slideUp(500);
            }else{
                $('#rocketmeluncur').slideDown(500);
            }
            var ftrocketmeluncur = $("#ft")[0] ? $("#ft")[0] : $(document.body)[0];
            var scrolltoprocketmeluncur = $('rocketmeluncur');
        var viewPortHeightrocketmeluncur = parseInt(document.documentElement.clientHeight);
        var scrollHeightrocketmeluncur = parseInt(document.body.getBoundingClientRect().top);
        var basewrocketmeluncur = parseInt(ftrocketmeluncur.clientWidth);
        var swrocketmeluncur = scrolltoprocketmeluncur.clientWidth;
        if (basewrocketmeluncur < 1000) {
        //var leftrocketmeluncur = parseInt(fetchOffset(ftrocketmeluncur)['left']);
        //leftrocketmeluncur = leftrocketmeluncur < swrocketmeluncur ? leftrocketmeluncur * 2 - swrocketmeluncur : leftrocketmeluncur;
        //scrolltoprocketmeluncur.style.left = ( basewrocketmeluncur + leftrocketmeluncur ) + 'px';
        } else {
       // scrolltoprocketmeluncur.style.left = 'auto';
        //scrolltoprocketmeluncur.style.right = '10px';
        }
        })
        
        $('#rocketmeluncur').click(function(){
            $("html, body").animate({ scrollTop: '0px',display:'none'},{
                    duration: 600,  
                    easing: 'linear'
                });
            
            var self = this;
            this.className += ' '+"launchrocket";
            setTimeout(function(){
              self.className = 'showrocket';
            },800)
        });
    }
    render() {
        return (
            <section id={`${this.props.id}`} className="about" style={{ height: this.context.height }}>
                {this.particles()}
                <Row>
                    <Col md={4} className="content">
                        <div className="content-text">
                            <h3>Artificial Intelligence from the Ancient One</h3>
                            <div className="separator" />
                            <p>Aparnia is a team of interdisciplinary scientists, engineers, innovators and more, working together to realise our shared goals. We research and build AI based products and solutions. We collaborate with various disciplines and follow a holistic approach to problem solving. We follow ethical practices to bring a balance to science and life.</p>
                        </div>
                    </Col>
                    <Col md={1} className="side">
                        <h2>
                            <BaffleText
                                text="Our Team"

                            />
                        </h2>
                    </Col>
                    <Col md={2} className="skills">
                        <div className="skills-container">
                            <Progress name="Vishwanatha Shiva" value={100} delay={1100} />
                            <Progress name="Saraswathi Jayaraman" value={100} delay={1100} />
                            <Progress name="Anima Lakshmi " value={100} delay={1100} />
                            <Progress name="Lakshmi Gnanam" value={100} delay={1100} />
                            <Progress name="Ramana Balasubramaniam " value={100} delay={1100} />
                            <Progress name="Sujay Badrinath" value={100} delay={1100} />
                            <Progress name="Vijay Premanand" value={100} delay={1100} />
                            <Progress name="Swaroop RS" value={100} delay={1100} />
                        </div>
                    </Col>
                    <Col md={2} className="skills">
                        <div className="skills-container">
                            <Progress name="Saketharaman NS" value={100} delay={1100} />
                            <Progress name="Aditya Chandrasekar" value={100} delay={1100} />
                            <Progress name="Akshaya Chandrasekar " value={100} delay={1100} />
                            <Progress name="Lakshmanan MS" value={100} delay={1100} />
                            <Progress name="Rohini Sukumar" value={100} delay={1100} />
                            <Progress name="Vijayalakshmi TS" value={100} delay={1100} />
                            <Progress name="Nadikerianda Cariappa" value={100} delay={1100} />
                            <Progress name="Kaveri&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; KT" value={100} delay={1100} />
                        </div>
                    </Col>
                    <Col md={2} className="skills">
                        <div className="skills-container">
                            <Progress name="Anamika Natarajan" value={100} delay={1100} />
                            <Progress name="Pramod Narayanan " value={100} delay={1100} />
                            <Progress name="Dnyaneshwar V" value={100} delay={1100} />
                            <Progress name="Surbha&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; GS" value={100} delay={1100} />
                            <Progress name="Arathi Agarwal " value={100} delay={1100} />
                            <Progress name="Sanjay&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; S" value={100} delay={1100} />
                            <Progress name="Priyam&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; VS " value={100} delay={1100} />
                            <Progress name="Sharada Vishwanathan" value={100} delay={1100} />
                        </div>
                    </Col>

                </Row>
                <br/>
             {/*   <ScrollTop
                    text="^"
                    distance={50}
                    breakpoint={768}
                    style={{ fontSize: "12px" }}
                    icon={<FontAwesomeIcon icon={faChevronUp} />}
                />  */}
                <a href="javascript:void(0);" id="rocketmeluncur" class="showrocket" ><i></i></a>
            </section>
        )
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 50,
                            "density": {
                                "enable": false,
                                "value_area": 5000
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .8,
                            "color": "#000",
                        },
                        "size": {
                            "value": 1
                        },
                        "color": {
                            "value": "#000",
                        },
                        "links": {
                            "color": "#000",
                        },
                    },
                    "retina_detect": true
                }} />
        )
    }

}

export default Hero