import React from 'react'
import './styles.scss'

class Drop extends React.Component {

    render() {
        const { text } = this.props
        return (


            <div id="container">
                <p className="drop" data-text={text}>{text}</p>
                <div class="drop"></div>
            </div>

        )
    }
}
export default Drop