import React from 'react'
import './styles.scss'

class Copyright extends React.Component {

    render() {
        const { text } = this.props
        return (
            <div className="fixedCopyright">
                © {(new Date().getFullYear())} Aparnia Research & Development Inc.
            </div>

        )
    }
}
export default Copyright