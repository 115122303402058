import React from 'react'
import './styles.scss'

class Shadow extends React.Component {

    render() {
        const { text } = this.props
        return (
            <div className="shadow" data-shadow={text}>{text}</div>

        )
    }
}
export default Shadow